html {
  font-family: var(--font-family-main);
  font-size: calc(var(--s0, 1rem) + 0.15vw);
  font-weight: var(--font-weight-main, 400);
  letter-spacing: var(--letter-spacing, 0);
}

body {
  background-color: var(--color-background-main);
  color: var(--color-text-main);
}

/*
  Enable smooth scrolling if the user has not actively decided against 
  animations.
  */
@media (prefers-reduced-motion: no-preference) {
  html:focus-within {
    scroll-behavior: smooth;
  }
}

.site-footer,
.site-header,
body:not(.home) .site-main {
  padding-block: var(--space, 1.5rem);
}

/* 
Apply measure to all elements but exempt typical block
elements.
*/
* {
  max-inline-size: var(--measure);
}

a,
article,
body,
div,
figure,
footer,
form,
header,
html,
hr,
main,
nav,
pre,
section,
.menu,
.pagination,
.search-results *,
/* Exclude all SplideJS components */
[class^="splide"],
/* Exclude all FullCalendar components */
[class^="fc-"],
[class^="fc-"] table {
  max-inline-size: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family-header);
  font-weight: var(--font-weight-header, 600);
  hyphens: auto;
  line-height: var(--line-height);
  margin-block: 1em 0.5em;
  max-inline-size: var(--max-inline-size);
}

h1 a,
h2 a,
h3 a,
h4 a {
  display: inline-block;
}

h1 {
  font-size: var(--s3);
}

h2 {
  font-size: var(--s2);
}

h3 {
  font-size: var(--s1);
}

h4,
h5,
h6 {
  font-size: var(--s0);
}
