.menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu a {
  display: inline-block;
  padding: 0.35rem;
  text-decoration: none;
  text-transform: var(--text-transform-menu, none);
}

#site-navigation-toggle {
  background: transparent;
  border: 0;
}

.no-js #site-navigation-toggle {
  display: none;
}

.js body.site-navigation-open {
  overflow: hidden;
}

.js .site-navigation-open #site-navigation-toggle .icon-navigation {
  display: none;
}

.js .site-navigation-open #site-navigation-toggle .icon-cross {
  display: inline-block;
}

.js .site-navigation-open #site-navigation-toggle {
  position: relative;
  z-index: var(--z4);
}

.js #site-navigation-toggle .icon-cross {
  display: none;
}

@media screen and (min-width: 900px) {
  #site-navigation-toggle {
    display: none;
  }
}

@media screen and (max-width: 899px) {
  .js .site-navigation {
    align-items: center;
    background: var(--color-background-site-navigation);
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    left: 0;
    opacity: 0;
    overflow-y: auto;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;
    z-index: var(--z4);
  }

  .js .site-navigation ul li {
    margin-block-end: 1.5rem;
    margin-inline-start: 0;
    text-align: center;
    width: 100%;
  }

  .js .site-navigation ul {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-block: var(--s5) var(--s2);
    width: max-content;
  }

  .js .site-navigation li {
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
    visibility: hidden;
  }

  .js .site-navigation li:nth-child(1) {
    transition-delay: 0.2s;
  }

  .js .site-navigation li:nth-child(2) {
    transition-delay: 0.3s;
  }

  .js .site-navigation li:nth-child(3) {
    transition-delay: 0.4s;
  }

  .js .site-navigation li:nth-child(4) {
    transition-delay: 0.5s;
  }

  .js .site-navigation li:nth-child(5) {
    transition-delay: 0.6s;
  }

  .js .site-navigation li:nth-child(6) {
    transition-delay: 0.7s;
  }

  .js .site-navigation li:nth-child(7) {
    transition-delay: 0.8s;
  }

  .js .site-navigation li:nth-child(8) {
    transition-delay: 0.9s;
  }

  .js .site-navigation li:nth-child(9) {
    transition-delay: 1s;
  }

  .js .site-navigation a {
    display: block;
    font-size: 1.5rem;
    padding: 1.5rem;
  }

  .js .site-navigation a + a {
    margin: 1.5rem 0 0;
  }

  .js .site-navigation-open .site-navigation {
    opacity: 1;
    visibility: visible;
  }

  .js .site-navigation-open .site-navigation li {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }
}
