a {
  color: var(--color-link);
  text-decoration: none;
}

a:focus {
  outline: 2px solid var(--color-link, #000);
  outline-offset: 2px;
}

a:hover {
  text-decoration: underline;
  text-decoration-color: currentColor;
  text-decoration-thickness: 2px;
}
