.row {
  --gap: var(--space, 1.5rem);
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: var(--gap);
}

/* .row > * {
  margin-inline: 0;
}

.row > * + * {
  margin-inline-start: var(--space, 1.5rem);
} */

.row--split-1 > :first-child {
  margin-inline-end: auto;
}

.row--split-2 > :nth-child(2) {
  margin-inline-end: auto;
}

.row--split-3 > :nth-child(3) {
  margin-inline-end: auto;
}

.row--align-end {
  align-items: flex-end;
}

.row--justify-between {
  justify-content: space-between;
}

.row--justify-end {
  justify-content: flex-end;
}

.row--reverse {
  flex-direction: row-reverse;
}
