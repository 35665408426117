#dmp-lightbox {
  background: rgba(0, 0, 0, 0.8);
  height: 100%;
  left: 0;
  opacity: 0 !important;
  position: fixed;
  top: 0;
  transition: opacity 0.2s cubic-bezier(0.42, 0.65, 0.27, 0.99),
    visibility 0.2s cubic-bezier(0.42, 0.65, 0.27, 0.99);
  visibility: hidden !important;
  width: 100%;
  z-index: var(--z4);
}

#dmp-lightbox.dmp-lightbox-visible {
  opacity: 1 !important;
  transition: opacity 0.2s cubic-bezier(0.42, 0.65, 0.27, 0.99) 0.1s,
    visibility 0.2s cubic-bezier(0.42, 0.65, 0.27, 0.99) 0.1s;
  visibility: visible !important;
}

#dmp-lightbox .splide__slide {
  align-items: center;
  display: flex;
  justify-content: center;
}

#dmp-lightbox .splide__slide img {
  max-block-size: 100%;
  max-inline-size: 100%;
}

/* 
  Styles for the close buttons are taken from button styles of SplideJS because
  SplideJS is used for the lightbox.  
*/
#dmp-lightbox #dmp-lightbox-close {
  align-items: center;
  background: #ccc;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  font-weight: bold;
  height: 2em;
  justify-content: center;
  opacity: 0.7;
  padding: 0;
  position: absolute;
  right: 1em;
  top: 2em;
  transform: translateY(-50%);
  width: 2em;
  z-index: 1;
}

#dmp-lightbox #dmp-lightbox-close:hover:not(:disabled) {
  opacity: 0.9;
}

#dmp-lightbox.is-focus-in #dmp-lightbox-close:focus {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.gallery-images.lightbox a:focus {
  outline: 2px solid var(--color-link, #000);
  outline-offset: -2px;
  padding: 4px;
}
